function isInternetExplorer(): boolean {
  /**
   * `MSIE ` - regex for checking IE 10 or older
   * `Trident/` - regex for checking IE 11
   */
  const ieMatcher: RegExp = /Trident\/|MSIE /;
  return ieMatcher.test(window.navigator.userAgent);
}

function isWrappedInIframe() {
  return window.self !== window.top;
}

export default {
  isInternetExplorer,
  isWrappedInIframe,
};
