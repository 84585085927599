import React__default, { createContext, useContext, useRef, useEffect, useState } from 'react';

function createCtx() {
    var ctx = createContext(undefined);
    function useCtx() {
        var c = useContext(ctx);
        if (!c)
            throw new Error('useCtx must be inside a Provider with a value');
        return c;
    }
    return [useCtx, ctx.Provider];
}
function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
}

var _a;
var useTheme = (_a = createCtx(), _a[0]), ThemeContextProvider = _a[1];

function ThemeProvider(props) {
    var _a = useState(props.initialTheme), theme = _a[0], setTheme = _a[1];
    var linksRef = useRef({});
    var previousTheme = usePrevious(theme);
    useEffect(function () {
        var links = linksRef.current;
        if (!links[theme]) {
            var link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = require("styles/themes/" + theme + "/" + theme + "-theme.less");
            if (document.head) {
                document.head.appendChild(link);
                links[theme] = link;
            }
        }
        else {
            links[previousTheme].disabled = true;
            links[theme].disabled = false;
        }
    }, [theme, previousTheme]);
    function changeTheme(theme) {
        setTheme(theme);
        if (props.onThemeChange) {
            props.onThemeChange(theme);
        }
    }
    return React__default.createElement(ThemeContextProvider, { value: [theme, changeTheme] }, props.children);
}

export { ThemeProvider, useTheme };
