import storage from 'local-storage-fallback';
import { Theme, themes } from '../themes';

abstract class ThemeStorageService {
  private static readonly key = 'theme';

  static getTheme(): Theme {
    const value = storage.getItem(ThemeStorageService.key);
    return themes.indexOf(value as Theme) >= 0 ? (value as Theme) : Theme.dark;
  }

  static saveTheme(theme: Theme): void {
    storage.setItem(ThemeStorageService.key, theme);
  }
}

export default ThemeStorageService;
