import { ReactComponent as ExternalLinkIcon } from 'images/icons/external-link.svg';
import React from 'react';
import BrandLogo from '../BrandLogo/BrandLogo';
import ExternalLink from '../ExternalLink/ExternalLink';
import IconText from '../IconText/IconText';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch';

interface HeaderProps {
  showThemeSwitch: boolean;
}

function Header(props: HeaderProps) {
  return (
    <header className={'header'}>
      <ExternalLink href="https://www.dxfeed.com" className={'logo-link'}>
        <BrandLogo />
      </ExternalLink>

      <div className={'header-right'}>
        {props.showThemeSwitch && <ThemeSwitch />}

        <ExternalLink href="https://www.dxfeed.com/contact-us/" className={'contact-us'}>
          <IconText icon={<ExternalLinkIcon />} align={'right'}>
            Contact us
          </IconText>
        </ExternalLink>
      </div>
    </header>
  );
}

export default Header;
