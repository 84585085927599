import * as React from 'react';

// This value is set in CSS
const cssValue = '10px';

interface State {
  stylesLoaded: boolean;
}

interface StylesheetSuspenseProps {
  children: React.ReactNode;
  onLoaded?(): void;
}

class StylesheetSuspense extends React.Component<StylesheetSuspenseProps, State> {
  private static readonly styles: React.CSSProperties = { display: 'none', width: 1 };
  private readonly ref = React.createRef<HTMLDivElement>();

  constructor(props: StylesheetSuspenseProps) {
    super(props);
    this.state = {
      stylesLoaded: false,
    };
  }

  componentDidMount(): void {
    const el = this.ref.current;
    if (el) {
      const pooling = setInterval(() => {
        const { width } = getComputedStyle(el);
        if (width === cssValue) {
          clearInterval(pooling);
          this.setState({ stylesLoaded: true });
          if (this.props.onLoaded) {
            this.props.onLoaded();
          }
        }
      }, 10);
    }
  }

  render() {
    const { stylesLoaded } = this.state;

    if (!stylesLoaded) {
      return (
        <div ref={this.ref} className={'stylesheet-load-check'} style={StylesheetSuspense.styles} />
      );
    }
    return this.props.children;
  }
}

export default StylesheetSuspense;
