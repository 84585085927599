import React from 'react';
import RcTooltip from 'rc-tooltip';
import { TooltipProps as RcTooltipProps } from 'rc-tooltip/lib/Tooltip';
import './Tooltip.common.css';

export type TooltipProps = RcTooltipProps;

const tooltipContainerElement = document.getElementById('root');
function getTooltipContainer() {
  return tooltipContainerElement ? tooltipContainerElement : document.body;
}

export function Tooltip(props: TooltipProps) {
  return <RcTooltip {...props} getTooltipContainer={getTooltipContainer} />;
}
