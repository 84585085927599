import React from 'react';
import clsx from 'clsx';

interface IconTextProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  align?: 'left' | 'right';
  className?: string;
}

/**
 * IconText component designed to combine icons with text that should be hovered together.
 */
function IconText(props: IconTextProps) {
  const { icon, align = 'left' } = props;

  return (
    <div className={clsx('app-icon-text', props.className)}>
      {align === 'left' && icon}
      {props.children}
      {align === 'right' && icon}
    </div>
  );
}

export default IconText;
