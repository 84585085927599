import React from 'react';
import clsx from 'clsx';

interface SwitchProps {
  checked: boolean;
  onChange(checked: boolean): void;
  checkedIcon?: React.ReactNode;
  uncheckedIcon?: React.ReactNode;
  buttonIcon?: React.ReactNode;
}

function Switch(props: SwitchProps) {
  const labelLink = 'dxfd-switch';

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(e.target.checked);
    }
  }

  return (
    <div className={clsx('dxfd-switch', props.checked && 'dxfd-switch-checked')}>
      <input
        type="checkbox"
        id={labelLink}
        className={'dxfd-switch-checkbox'}
        checked={props.checked}
        onChange={onChange}
      />
      <label htmlFor={labelLink} className={'dxfd-switch-label'}>
        <div className={'dxfd-switch-button'}>
          <div className={'dxfd-switch-button-icon'}>{props.buttonIcon}</div>
        </div>
        <div className={'dxfd-switch-unchecked-icon'}>{props.uncheckedIcon}</div>
        <div className={'dxfd-switch-checked-icon'}>{props.checkedIcon}</div>
      </label>
    </div>
  );
}

export default Switch;
