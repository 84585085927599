import React, { useRef, Suspense } from 'react';
import Header from '../Header/Header';
import StylesheetSuspense from '../StylesheetSuspense/StylesheetSuspense';
import ThemeStorageService from 'services/ThemeStorageService';
import LoaderService from 'services/LoaderService';
import DetectionService from 'services/DetectionService';
import { Theme } from '../../themes';
import { ThemeProvider } from 'theme-provider';

const Radar = React.lazy(() => import('../Radar/Radar'));
const UnsupportedBrowsers = React.lazy(() => import('../UnsupportedBrowsers/UnsupportedBrowsers'));

function App() {
  const initialThemeRef = useRef<Theme>(ThemeStorageService.getTheme());
  const isIeRef = useRef(DetectionService.isInternetExplorer());

  return (
    <ThemeProvider
      initialTheme={initialThemeRef.current}
      onThemeChange={ThemeStorageService.saveTheme}
    >
      <StylesheetSuspense onLoaded={LoaderService.hideLoader}>
        <Header showThemeSwitch={!isIeRef.current} />
        <Suspense fallback={null}>{isIeRef.current ? <UnsupportedBrowsers /> : <Radar />}</Suspense>
      </StylesheetSuspense>
    </ThemeProvider>
  );
}

export default App;
