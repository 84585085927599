import React, { useCallback } from 'react';
import Switch from '../Switch/Switch';
import { ReactComponent as SunIcon } from 'images/icons/sun.svg';
import { ReactComponent as CrescentIcon } from 'images/icons/crescent.svg';
import { Theme } from '../../themes';
import { useTheme } from 'theme-provider';
import { Tooltip } from '../Tooltip/Tooltip';

function ThemeSwitch() {
  const [theme, changeTheme] = useTheme();

  const onChange = useCallback(() => changeTheme(theme === Theme.dark ? Theme.light : Theme.dark), [
    theme,
    changeTheme,
  ]);

  const isChecked = theme !== Theme.dark;
  const tooltipMsg = `Switch to ${theme === Theme.dark ? 'light' : 'dark'} theme`;
  // Tooltip requires child node accepts onMouseEnter, onMouseLeave, onFocus, onClick event,
  // so it wrapped with div element
  return (
    <Tooltip overlay={tooltipMsg} placement={'bottom'}>
      <div>
        <Switch
          checked={isChecked}
          onChange={onChange}
          checkedIcon={<CrescentIcon />}
          uncheckedIcon={<SunIcon fill={'#8a9199'} />}
          buttonIcon={isChecked ? <SunIcon /> : <CrescentIcon fill={'white'} />}
        />
      </div>
    </Tooltip>
  );
}

export default ThemeSwitch;
