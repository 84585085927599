import React from 'react';
import { ReactComponent as DarkLogo } from 'images/brand/dxFeed-dark.svg';
import { ReactComponent as LightLogo } from 'images/brand/dxFeed-light.svg';
import { Theme } from '../../themes';
import { useTheme } from 'theme-provider';

function BrandLogo() {
  const [theme] = useTheme();
  return theme === Theme.dark ? <DarkLogo /> : <LightLogo />;
}

export default BrandLogo;
